import React, { useContext } from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
// import { Link } from "react-scroll";

import { Title, Button, Section, Box, Text } from '../../components/Core'

// import { device } from "../../utils";

import GlobalContext from '../../context/GlobalContext'
import Lottie from 'lottie-react'
import bulb from '../../assets/lottie/bulb.json'
const ImgRight = styled.img`
  max-width: 100%;
`

const Hero = () => {
  const gContext = useContext(GlobalContext)
  // console.log('hi')
  return (
    <>
      {/* <!-- Hero Area --> */}
      <Section hero className="position-relative">
        <Container>
          <Row className="align-items-center">
            <Col lg="5" md="8" sm="9">
              <div className="text-center text-lg-right position-relative">
                <div className="img-main">
                  <Lottie animationData={bulb} loop />
                  {/* <ImgRight src={imgL} alt="" /> */}
                </div>
              </div>
            </Col>
            <Col lg="6" className="mb-5 mb-lg-0">
              <Box>
                <Text
                  variant="tag"
                  className="text-uppercase"
                  color="heading"
                  mb="40px"
                >
                  UI/UX DESIGNER, JUNIOR FRONT-END DEV
                </Text>
                <Title variant="hero">Hi, I'm Beta</Title>

                <Text>
                  UI/UX designer passionate about the intersection of
                  human-centered design and technology.
                  <br />  I’m focused on developing solutions that are smart,
                  accessible, and compatible with web development platforms.{' '}
                </Text>

                <Box mt="52px">
                  <Button
                    arrowRight
                    onClick={e => {
                      e.preventDefault()
                      gContext.toggleContact()
                    }}
                  >
                    Get in touch{' '}
                  </Button>
                </Box>
              </Box>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  )
}

export default Hero
