import React, { useState, useEffect } from 'react'
import { Container, Col } from 'react-bootstrap'
import Masonry from 'react-masonry-component'

import { Section, Box, ListNav } from '../../components/Core'
import WorkCard from '../../components/WorkCard'
import { projects } from '../../data'

const Works = () => {
  const [items, setItems] = useState([])
  const [activeLink, setActiveLink] = useState('*')
  useEffect(() => {
    setItems(projects)
  }, [])

  const filterBy = cat => {
    if (cat === '*') {
      setActiveLink('*')
      setItems(projects)
    } else {
      const filteredItems = projects.filter(item => {
        return item.categories.indexOf(cat) !== -1
      })
      setActiveLink(cat)
      setItems(filteredItems)
    }
  }

  const masonryOptions = {
    transitionDuration: 2000,
  }

  return (
    <>
      {/* <!-- Works Area --> */}
      <Section className="position-relative" id="works">
        <Container>
          <Box mb="2.5rem" ml="-1.75rem">
            <ListNav className="nav">
              <li className="nav-item">
                <a
                  className={`nav-link font-weight-bold text-uppercase ${activeLink === '*' ? 'active' : null
                    }`}
                  onClick={e => {
                    e.preventDefault()
                    filterBy('*')
                  }}
                >
                  All works
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link font-weight-bold text-uppercase ${activeLink === 'ui/UX Design' ? 'active' : null
                    }`}
                  onClick={e => {
                    e.preventDefault()
                    filterBy('ui/UX Design')
                  }}
                >
                  UI/UX DESIGN
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link font-weight-bold text-uppercase ${activeLink === 'coding' ? 'active' : null
                    }`}
                  onClick={e => {
                    e.preventDefault()
                    filterBy('coding')
                  }}
                >
                  CODING
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link font-weight-bold text-uppercase ${activeLink === 'fashion' ? 'active' : null
                    }`}
                  onClick={e => {
                    e.preventDefault()
                    filterBy('fashion')
                  }}
                >
                  FASHION STYLING
                </a>
              </li>
            </ListNav>
          </Box>
        </Container>

        <Container fluid>
          <Masonry
            updateOnEachImageLoad={true}
            onImagesLoaded={() => console.log("All images loaded")}
            options={masonryOptions}
            className={'masonry-grid row'} // default ''
          >
            {items.map((item, index) => (
              <Col lg="3" md="4" sm="6" key={item.slug} className="filtr-item">
                <WorkCard workItem={item} mb="30px" link={item.link} />
              </Col>
            ))}
          </Masonry>
        </Container>
      </Section>
    </>
  )
}

export default Works
